<template>
    <div>
        <mdb-row>
            <mdb-col md="4">
                <mdb-select label="目前分店"
                            placeholder="選擇目前分店"
                            @getValue="val=>{orders.id_store=val; get_store_stock() }"
                            v-model="storeList"
                            v-if="type=='add'"></mdb-select>
                <mdb-input label="目前分店"
                           disabled
                           :value="storeKeyList[orders.id_store]?storeKeyList[orders.id_store].text:''"
                           v-if="type!='add'"></mdb-input>
            </mdb-col>
            <mdb-col md="4">
                <datepicker label="日期"
                            v-model="orders.day"
                            :max="new Date()"
                            v-if="type!='view'" />
                <mdb-input label="日期"
                           disabled
                           :value="orders.day"
                           v-show="type=='view'"></mdb-input>
            </mdb-col>
            <mdb-col md="4">
                訂單狀態：
                <mdb-badge color="success"
                           v-show="orders.status=='0'">已完成</mdb-badge>
                <mdb-badge color="warning"
                           v-show="orders.status=='1'">未結帳</mdb-badge>
                <router-link :to="`/orders/edit/${orders.id}/Y`"
                             v-show="type=='view'">
                    <mdb-btn color="blue-grey"
                             size="sm"
                             style="float:right">
                        <i class="fa fa-pen"></i>
                        &nbsp;編輯
                    </mdb-btn>
                </router-link>
            </mdb-col>
        </mdb-row>
        <mdb-card class="mb-2">
            <mdb-card-body>
                <mdb-card-title color="default">顧客資料
                    <mdb-btn color="primary"
                             size="sm"
                             @click="search_customer.show=true;get_customerList()"
                             v-show="type=='add'">
                        <i class="fa fa-search"></i>
                        &nbsp;新增客戶資料
                    </mdb-btn>
                    <mdb-btn color="primary"
                             size="sm"
                             @click="orders.customer_device = [{id:''}];"
                             v-show="!select_customer">
                        無客戶資料
                    </mdb-btn>
                </mdb-card-title>
                <mdb-row v-show="select_customer">
                    <mdb-col md="4"
                             v-show="type=='add' || orders.id_customer!='0'">
                        <mdb-input label="姓名"
                                   v-model="orders.name"
                                   :disabled="type=='view'" />
                        <small v-show="type=='edit'">注意：修改訂單資料不會更新客資！</small>
                    </mdb-col>
                    <mdb-col md="4"
                             v-show="type=='add' || orders.id_customer!='0'">
                        <mdb-input label="連絡電話"
                                   v-model="orders.cel"
                                   :disabled="type=='view'" />
                    </mdb-col>
                    <mdb-col md="4">
                        {{ type=='view' ? '無客戶資料' : ''}}
                    </mdb-col>
                    <mdb-col col="12"
                             md="9"
                             v-show="type=='add'">
                        <mdb-select label="維修項目"
                                    multiple
                                    placeholder="請選取維修項目"
                                    v-model="customer_deviceList"
                                    @change="switch_customer_device()"
                                    :show="!add_customer_device.show"></mdb-select>
                        <div v-for="c in customer_deviceList"
                             :key="`cd_${c.id}`"> <small v-if="c.selected && c.id">{{repair_targetKeyList[c.id_repair_target].name}}，
                                序號：{{c.sn}}，顏色：{{c.color}}</small></div>
                    </mdb-col>
                    <mdb-col class="text-md-left text-right"
                             col="12"
                             md="3"
                             v-show="type=='add'">
                        <mdb-btn color="blue-grey"
                                 size="sm"
                                 @click="edit_customer_device.show=true;"
                                 v-if="customer_deviceList.length > 0 && customer_deviceList[0].value != 'add'">
                            <i class="fa fa-pen"></i>
                            &nbsp;維護項目
                        </mdb-btn>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <transition name="fade"
                    v-for="(order_item,order_itemkey) in get_orders_itemList"
                    :key="`order_item_${order_itemkey}`">
            <mdb-card class="mb-2">
                <mdb-card-header style="color:#fff">
                    {{ order_item.customer_device.id!='' ? repair_targetKeyList[order_item.customer_device.id_repair_target].name : '門市銷售'}}
                    {{ order_item.customer_device.id!='' ? `，序號：${order_item.customer_device.sn}` : '' }}
                    <mdb-btn class="float-right m-0"
                             size="sm"
                             @click="show_repairLog(order_item.customer_device)"
                             v-if="order_item.customer_device.id!=''">
                        <i class="fa fa-search"></i>
                        &nbsp;
                        維修紀錄
                    </mdb-btn>
                </mdb-card-header>
                <mdb-card-body>
                    <div class="rwd-table-dispersion">
                        <table class="table table-striped table-hover"
                               style="width:100%">
                            <thead>
                                <tr>
                                    <th style="width:3rem">#</th>
                                    <th>商品名稱</th>
                                    <th style="width:10rem">金額</th>
                                    <th style="width:5rem">數量</th>
                                    <th style="width:10rem">保固</th>
                                    <th style="width:5rem"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(oi,oik) in order_item.data"
                                    :key="`oi_${oik}`">
                                    <td data-title="#">{{oik+1}}</td>
                                    <td data-title="商品名稱">
                                        <div :style="oi.remove=='1' ? 'text-decoration: line-through' : ''">
                                            <div v-if="productKeyList[oi.id_product]">
                                                {{ productKeyList[oi.id_product].name }}<br>
                                                <small style="color:#999">
                                                    {{ productKeyList[oi.id_product].code }}
                                                </small>
                                            </div>
                                            <div v-else>
                                                {{ oi.product_name }}<br>
                                                <small>{{ oi.product_code }}</small>
                                            </div>
                                        </div>
                                        <small v-show="oi.remove==1 && oi.del_return==0">
                                            銷貨退回：
                                            <span style="color:#f00">報廢</span>
                                        </small>
                                        <small v-show="oi.remove==1 && oi.del_return==1">
                                            銷貨退回：
                                            <span style="color:#0179D4">退回庫存</span>
                                        </small>
                                    </td>
                                    <td data-title="金額"
                                        :style="oi.remove=='1' ? 'text-decoration: line-through' : ''">
                                        <mdb-input class="m-0"
                                                   type="number"
                                                   v-model.number="oi.price"
                                                   v-show="type!='view' && oi.remove=='0'" />
                                        {{ type=='view' || oi.remove=='1' ? $numeral(oi.price).format('0,0') : '' }}
                                    </td>
                                    <td data-title="數量"
                                        :style="oi.remove=='1' ? 'text-decoration: line-through' : ''">
                                        <mdb-input class="m-0"
                                                   type="number"
                                                   :min="1"
                                                   :max="oi.stock"
                                                   v-model.number="oi.amount"
                                                   v-show="type=='add' || orders.status!='0'" />
                                        {{ type!='add' && orders.status=='0' ? oi.amount : '' }}
                                    </td>
                                    <td data-title="保固"
                                        :style="oi.remove=='1' ? 'text-decoration: line-through' : ''">
                                        <select class="  custom-select"
                                                v-model="oi.id_opt_warranty"
                                                v-show="type!='view' && oi.remove=='0'">
                                            <option value=""
                                                    selected>選擇保固方式</option>
                                            <option v-for="(w,wk) in opt_warrantyList"
                                                    :value="w.id"
                                                    :key="`wk_${wk}`">{{w.text}}</option>
                                        </select>
                                        {{ warrantyKeyList[oi.id_opt_warranty] && (type=='view' || oi.remove=='1') ? warrantyKeyList[oi.id_opt_warranty].text : '' }}
                                    </td>
                                    <td>
                                        <mdb-btn color="danger"
                                                 size="sm"
                                                 @click="del_order_item(oi);"
                                                 v-show="type!='view' && oi.remove==0">
                                            <i class="fa fa-trash"></i>
                                        </mdb-btn>
                                    </td>
                                </tr>
                                <tr v-show="order_item.data.length==0">
                                    <td colspan="6">無資料</td>
                                </tr>
                            </tbody>
                            <tbody v-show="type=='add' || (type=='edit' && orders.status!='0')">
                                <tr class="d-none"></tr>
                                <tr>
                                    <td class="d-none d-md-table-cell"
                                        style="color:#999"><i class="fa fa-plus"></i></td>
                                    <td colspan="5"
                                        data-title="新增品項">
                                        <mdb-row>
                                            <mdb-col sm="6"
                                                     md="3"
                                                     class="mb-1"
                                                     v-show="order_item.customer_device.id==''">
                                                <SearchSelect placeholder="=== 維修機種 ==="
                                                              :options="order_item_select[order_itemkey].targetList"
                                                              v-model="new_product_target[order_item.customer_device.id]"></SearchSelect>
                                            </mdb-col>
                                            <mdb-col sm="6"
                                                     md="3"
                                                     class="mb-1">
                                                <SearchSelect placeholder="=== 選取分類 ==="
                                                              :options="order_item_select[order_itemkey].classList"
                                                              v-model="new_product_class[order_item.customer_device.id]"></SearchSelect>
                                            </mdb-col>
                                            <mdb-col sm="6"
                                                     md="3">
                                                <SearchSelect placeholder="=== 選取商品 ==="
                                                              :options="order_item_select[order_itemkey].productList"
                                                              v-model="new_product[order_item.customer_device.id]"></SearchSelect>
                                            </mdb-col>
                                            <mdb-col sm="6"
                                                     md="3">
                                                <mdb-btn size="sm"
                                                         @click="add_orders_item(order_item)">
                                                    <i class="fa fa-plus"></i>
                                                    &nbsp;增加
                                                </mdb-btn>
                                            </mdb-col>
                                        </mdb-row>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </mdb-card-body>
            </mdb-card>
        </transition>
        <p class="text-right pr-2"
           v-show="get_total_price>0">
            總金額：{{$numeral(get_total_price).format("0,0")}}
        </p>
        <mdb-card class="mb-2">
            <mdb-card-body>
                <mdb-row>
                    <mdb-col md="4">
                        <mdb-select label="付款方式"
                                    placeholder="付款方式"
                                    v-model="opt_paymentList"
                                    @getValue="val=>orders.id_opt_payment=val"
                                    v-show="type!='view'"></mdb-select>
                        <mdb-input disabled
                                   label="付款方式"
                                   :value="paymentKeyList[orders.id_opt_payment] ? paymentKeyList[orders.id_opt_payment].text : ''"
                                   v-if="type=='view'"></mdb-input>
                    </mdb-col>
                    <mdb-col md="4">
                        <mdb-input label="統編"
                                   v-model="orders.uni_code"
                                   v-show="type!='view'" />
                        <mdb-input disabled
                                   label="統編"
                                   :value="orders.uni_code"
                                   v-if="type=='view'"></mdb-input>
                    </mdb-col>
                    <mdb-col md="4">
                        <!-- <mdb-input label="發票號碼"
                                   v-model="orders.invoice_code" /> -->
                    </mdb-col>
                    <mdb-col md="4">
                    </mdb-col>
                </mdb-row>
                <mdb-input type="textarea"
                           label="備註"
                           v-model="orders.remark"
                           :disabled="type=='view'"></mdb-input>
            </mdb-card-body>
        </mdb-card>
        <div class="text-right mb-4"
             v-show="type!='view'">
            <mdb-btn color="info"
                     @click="save_orders('keep')"
                     v-if="orders.status!='0'">
                <i class="fa fa-check"></i>
                &nbsp;暫存
            </mdb-btn>
            <mdb-btn color="default"
                     @click="save_orders('send')">
                <i class="fa fa-save"></i>
                &nbsp;儲存
            </mdb-btn>
        </div>
        <!-- 搜尋客戶資料 start -->
        <div>
            <mdb-modal size="lg"
                       :show="search_customer.show"
                       @close="search_customer.show = false">
                <mdb-modal-header class="text-center">新增客戶資料
                </mdb-modal-header>
                <mdb-modal-body class="mx-3 grey-text">
                    <mdb-row>
                        <mdb-col lg="4">
                            <mdb-input label="姓名"
                                       icon="user"
                                       type="text"
                                       v-model="search_customer.name"
                                       @change="search_customer.edit=true;"
                                       @keyup.enter.native="get_customerList();" />
                        </mdb-col>
                        <mdb-col lg="4">
                            <mdb-input label="手機"
                                       icon="phone"
                                       type="text"
                                       v-model="search_customer.cel"
                                       @change="search_customer.edit=true;"
                                       @keyup.enter.native="get_customerList();" />
                        </mdb-col>
                        <mdb-col lg="4" class="text-right text-lg-left">
                          <mdb-btn class="mt-lg-4 mb-2" size="sm" color="secondary" @click="get_customerList();">
                            <i class="fa fa-search"></i>
                            &nbsp;搜尋
                          </mdb-btn>
                        </mdb-col>
                        <mdb-col col="12"
                                 class="text-left"
                                 v-show="search_customer.edit">
                            <mdb-btn color="success"
                                     size="sm"
                                     @click="save_customer()">
                                <i class="fa fa-save"></i>
                                &nbsp;新增客戶資料
                            </mdb-btn>
                        </mdb-col>
                    </mdb-row>
                    <div class="rwd-table-dispersion">
                        <table class="table table-striped table-hover"
                               style="width:100%">
                            <thead>
                                <tr>
                                    <th style="width:3rem">#</th>
                                    <th style="width:10rem">姓名</th>
                                    <th style="width:10rem">電話</th>
                                    <!-- <th style="width:13rem">E-mail</th> -->
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(c,ck) in search_customer.customerList"
                                    :key="`c_${ck}`">
                                    <td>{{ck+1}}</td>
                                    <td>

                                        <mdb-input type="text"
                                                   class="my-0"
                                                   v-model="c.name"></mdb-input>
                                    </td>
                                    <td>
                                        <mdb-input type="text"
                                                   class="my-0"
                                                   v-model="c.cel"></mdb-input>
                                    </td>
                                    <!-- <td>
                                        <mdb-input type="email"
                                                   class="my-0"
                                                   v-model="c.email"></mdb-input>
                                    </td> -->
                                    <td>
                                        <mdb-btn color="info"
                                                 size="sm"
                                                 @click="get_customer_data(c)">
                                            <i class="fa fa-check"></i>
                                            &nbsp;選取
                                        </mdb-btn>
                                    </td>
                                </tr>
                                <tr v-show="search_customer.customerList.length==0">
                                    <td colspan="5"
                                        class="text-center">{{search_customer.searching?"搜尋中...":"無資料"}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </mdb-modal-body>
            </mdb-modal>
        </div>
        <!-- 搜尋客戶資料 end -->
        <!-- 新增客戶裝置資料 start -->
        <div>
            <mdb-modal size="md"
                       :show="add_customer_device.show"
                       @close="close_add_customer_device_form()">
                <mdb-modal-header class="text-center"
                                  @close="close_add_customer_device()">新增裝置資料
                </mdb-modal-header>
                <mdb-modal-body class="mx-3 grey-text">
                    <mdb-select label="維修機種"
                                search
                                v-model="repair_targetList"
                                @change="val=>{add_customer_device.data.id_repair_target=val;add_customer_device.colors=repair_targetKeyList[val].colors}"></mdb-select>
                    <mdb-input label="機身序號"
                               type="text"
                               v-model="add_customer_device.data.sn" />
                    <mdb-select label="顏色"
                                v-model="add_customer_device.colors"
                                @change="val=>add_customer_device.data.color=val"></mdb-select>
                    <mdb-input label="備註"
                               v-model="add_customer_device.data.remark"></mdb-input>
                </mdb-modal-body>
                <mdb-modal-footer right>
                    <mdb-btn size="sm"
                             @click="save_customer_device()">
                        <i class="fa fa-save"></i>
                        &nbsp;新增裝置
                    </mdb-btn>
                </mdb-modal-footer>
            </mdb-modal>
        </div>
        <!-- 新增客戶裝置資料 end -->
        <!-- 客戶裝置維修歷史紀錄資料 start -->
        <div>
            <mdb-modal size="lg"
                       :show="repairLog.show"
                       @close="close_repairLog_form()">
                <mdb-modal-header class="text-center">
                    {{
                    repair_targetKeyList[repairLog.customer_device.id_repair_target]?repair_targetKeyList[repairLog.customer_device.id_repair_target].name:''
                  }} 維修紀錄
                </mdb-modal-header>
                <mdb-modal-body class="mx-3 grey-text">
                    <table class="table table-hover"
                           v-if="!repairLog.get_data">
                        <thead>
                            <tr>
                                <th style="width:8rem">日期</th>
                                <th>名稱</th>
                                <th style="width:8rem">金額</th>
                                <th style="width:8rem">保固方式</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="oi in repairLog.list"
                                :key="`oi_${oi.id}`">
                                <td>{{oi.ctime.substr(0,10)}}</td>
                                <td>{{oi.product_name}} * {{oi.amount}}</td>
                                <td>{{oi.price*oi.amount}}</td>
                                <td>{{warrantyKeyList[oi.id_opt_warranty] ? warrantyKeyList[oi.id_opt_warranty].text : ''}}</td>
                            </tr>
                            <tr v-show="repairLog.list.length==0">
                                <td colspan="4">無資料</td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="repairLog.get_data">資料讀取中...</div>
                </mdb-modal-body>
            </mdb-modal>
        </div>
        <!-- 客戶裝置維修歷史紀錄資料 end -->
        <!-- 維護維修項目 start -->
        <div>
            <mdb-modal size="lg"
                       :show="edit_customer_device.show"
                       @close="edit_customer_device.show = false">
                <mdb-modal-header class="text-center">維護維修項目
                </mdb-modal-header>
                <mdb-modal-body class="mx-3 grey-text">
                    <div class="rwd-table-dispersion">
                        <table class="table table-striped table-hover"
                               style="width:100%">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>機種</th>
                                    <th style="width:10rem">序號</th>
                                    <th style="width:10rem">顏色</th>
                                    <!-- <th>備註</th> -->
                                </tr>
                            </thead>
                            <tbody v-for="(d, dk) in get_editcustomer_deviceList"
                                   :key="`cd_${dk}`">
                                <tr>
                                    <td data-title="#">{{dk+1}}</td>
                                    <td data-title="機種">
                                        {{d.text}}
                                    </td>
                                    <td data-title="序號"
                                        style="min-height:2.5em">
                                        {{!d.edit ? d.sn : ''}}
                                        <mdb-input v-model="d.sn"
                                                   v-show="d.edit"></mdb-input>
                                    </td>
                                    <td data-title="顏色"
                                        style="min-height:2.5em">
                                        {{!d.edit ? d.color : ''}}
                                        <mdb-select label="顏色"
                                                    v-model="customer_device_colors[d.id]"
                                                    @change="val=>d.color=val"
                                                    v-if="d.edit"></mdb-select>
                                    </td>
                                    <td data-title="備註"
                                        class="d-block d-md-none"
                                        style="min-height:2.5em">
                                        <mdb-input type="textarea"
                                                   class="m-0 p-0 remark_text"
                                                   v-model="d.remark"
                                                   :disabled="!d.edit"></mdb-input>
                                    </td>
                                    <td class="d-block d-md-none text-right">
                                        <mdb-btn color="blue-grey"
                                                 size="sm"
                                                 v-show="!d.edit"
                                                 @click="$set(d,'edit',true)">
                                            <i class="fa fa-edit"></i>
                                            編輯
                                        </mdb-btn>
                                        <mdb-btn color="default"
                                                 size="sm"
                                                 v-show="d.edit"
                                                 @click="save_customer_device_edit(d)">
                                            <i class="fa fa-save"></i>
                                            &nbsp;儲存
                                        </mdb-btn>
                                        <mdb-btn color="danger"
                                                 size="sm"
                                                 @click="del_customer_device(d)"
                                                 v-if="has_order_cp.indexOf(d.id)==-1">
                                            <i class="fa fa-trash"></i>
                                            &nbsp;刪除
                                        </mdb-btn>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="d-none d-md-table-cell">備註</td>
                                    <td data-title="備註"
                                        class="d-none d-md-table-cell">
                                        <mdb-input type="textarea"
                                                   class="m-0 p-0 remark_text"
                                                   v-model="d.remark"
                                                   :disabled="!d.edit"></mdb-input>
                                    </td>
                                    <td colspan="2"
                                        class="d-none d-md-table-cell text-right">
                                        <mdb-btn color="blue-grey"
                                                 size="sm"
                                                 v-show="!d.edit"
                                                 @click="$set(d,'edit',true)">
                                            <i class="fa fa-edit"></i>
                                            編輯
                                        </mdb-btn>
                                        <mdb-btn color="default"
                                                 size="sm"
                                                 v-show="d.edit"
                                                 @click="save_customer_device_edit(d)">
                                            <i class="fa fa-save"></i>
                                            &nbsp;儲存
                                        </mdb-btn>
                                        <mdb-btn color="danger"
                                                 size="sm"
                                                 @click="del_customer_device(d)"
                                                 v-if="has_order_cp.indexOf(d.id)==-1">
                                            <i class="fa fa-trash"></i>
                                            &nbsp;刪除
                                        </mdb-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </mdb-modal-body>
            </mdb-modal>
        </div>
        <!-- 維護維修項目 end -->
    </div>
</template>
<script>
import {
  mdbCard,
  mdbCardHeader,
  mdbCardTitle,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbInput,
  mdbBtn,
  mdbSelect,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbBadge,
} from "mdbvue";
import datepicker from "../../components/datepicker";
import SearchSelect from "../../components/SearchSelect";
export default {
  components: {
    mdbCard,
    mdbCardHeader,
    mdbCardTitle,
    mdbCardBody,
    mdbRow,
    mdbCol,
    mdbInput,
    mdbBtn,
    mdbSelect,
    datepicker,
    SearchSelect,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbBadge,
  },
  data() {
    return {
      orders: { customer_device: [] },
      storeList: [],
      opt_paymentList: [],
      opt_warrantyList: [],
      repair_targetList: [],
      customer_deviceList: [],
      productList: [],
      product_classList: [],

      customerEmpty: {},
      customer_deviceEmpty: {},
      orders_itemEmpty: {},

      select_customer: false,
      orders_itemList: [],
      new_product: {},
      new_product_class: {},
      new_product_target: {},
      search_customer: {
        name: "",
        cel: "",
        email: "",
        sn: "",
        edit: false,
        show: false,
        searching: false,
        customerList: [],
      },
      add_customer_device: {
        show: false,
        data: {},
        colors: [],
        checkmsg: "",
      },
      repairLog: {
        show: false,
        customer_device: {},
        list: [],
        get_data: true,
      },
      edit_customer_device: {
        show: false,
      },
      // 計算訂單項目數量(單純是產生新增用id的)
      orders_item_count: 0,
      // 有key單過的裝置
      has_order_cp: [],
    };
  },
  watch: {
    "$route.path": {
      handler() {
        if (this.$route.path == "/orders/add_order/") {
          location.reload();
        }
      },
      deep: true,
    },
  },
  mounted() {
    console.clear();
    this.get_data();
  },
  computed: {
    type() {
      if (this.$route.params.edit && this.$route.params.id) {
        return "edit";
      } else if (this.$route.params.id) {
        return "view";
      } else {
        return "add";
      }
    },
    repair_targetKeyList() {
      let vue = this,
        output = {};
      vue.repair_targetList.forEach((item) => {
        output[item.id] = item;
      });
      return output;
    },
    productKeyList() {
      let vue = this,
        output = {};
      vue.productList.forEach((item) => {
        output[item.id] = item;
      });
      return output;
    },
    warrantyKeyList() {
      let vue = this,
        output = {};
      vue.opt_warrantyList.forEach((item) => {
        output[item.id] = item;
      });
      return output;
    },
    storeKeyList() {
      let vue = this,
        output = {};
      vue.storeList.forEach((item) => {
        output[item.id] = item;
      });
      return output;
    },
    paymentKeyList() {
      let vue = this,
        output = {};
      vue.opt_paymentList.forEach((item) => {
        output[item.id] = item;
      });
      return output;
    },
    get_orders_itemList() {
      let vue = this,
        output = {};
      if (vue.select_customer) {
        let idx = vue.orders.customer_device.findIndex((item) => {
          return item.id == "";
        });
        // 判斷已選客戶
        if (
          idx == -1 &&
          (vue.type == "add" ||
            (vue.type == "edit" && vue.orders.status != "0"))
        ) {
          // 未新增過無 id 的門市銷售 且 狀態在新增及維護
          vue.orders.customer_device.unshift({ id: "" });
        }
      }
      for (let i in Object.values(vue.orders.customer_device)) {
        let item = vue.orders.customer_device[i];
        if (typeof vue.new_product[item.id] == "undefined") {
          // 建立 vue 選項資料
          vue.$set(vue.new_product, item.id, "");
          vue.$set(vue.new_product_class, item.id, "");
          vue.$set(vue.new_product_target, item.id, "");
        }
        if (!output[item.id_customer_device]) {
          output[item.id] = {
            customer_device: item,
            data: [],
            type: "",
          };
        }
      }
      vue.orders_itemList.forEach((item) => {
        if (output[item.id_customer_device]) {
          item.del = false;
          output[item.id_customer_device].data.push(item);
        } else {
          item.del = true;
        }
      });
      return output;
    },
    // 取出維護維修項目(去掉add)
    get_editcustomer_deviceList() {
      return this.customer_deviceList.filter((item) => {
        return item.value != "add";
      });
    },
    //
    order_item_select() {
      let vue = this,
        output = {};

      for (let ikey in vue.get_orders_itemList) {
        let order_item = vue.get_orders_itemList[ikey],
          id_class = vue.new_product_class[order_item.customer_device.id],
          id_target = vue.new_product_target[order_item.customer_device.id],
          index,
          class_ids = [];
        // 建立各 orders_itemList 的選項
        output[ikey] = {
          targetList: [],
          classList: [],
          productList: [],
        };
        // 維修項目
        vue.repair_targetList.forEach((pt) => {
          output[ikey].targetList.push({ key: pt.id, value: pt.name });
        });
        // 產品項目
        vue.productList.forEach((item) => {
          if (item.forall == "1") {
            // 全部適用的項目(維修之類的)
            index = 0;
          } else if (order_item.customer_device.id == "") {
            // 新增無客戶資料
            index = 0;
            if (id_target != "") {
              index = item.repair_target.findIndex((r) => {
                return parseInt(r) == parseInt(id_target);
              });
            }
          } else {
            index = item.repair_target.findIndex((r) => {
              return (
                parseInt(r) ==
                parseInt(order_item.customer_device.id_repair_target)
              );
            });
          }

          if (index > -1) {
            let p = {},
              class_array = JSON.parse(item.class);
            // 取產品對應分類
            class_ids = class_ids.concat(class_array);

            if (
              id_class == "" ||
              class_array.indexOf(parseInt(id_class)) > -1 ||
              class_array.indexOf(id_class.toString()) > -1
            ) {
              p.value = `${item.name} ( ${item.code} )`;
              p.key = item.id;
              p.disabled =
                order_item.data.findIndex((oi) => {
                  return oi.id_product == item.id;
                }) > -1;
              output[ikey].productList.push(p);
            }
          }
        });
        // 產品分類
        vue.product_classList.forEach((pc) => {
          if (
            class_ids.indexOf(parseInt(pc.id)) > -1 ||
            class_ids.indexOf(pc.id.toString()) > -1
          ) {
            pc.key = pc.id;
            pc.value = pc.name;
            output[ikey].classList.push(pc);
          }
        });
      }

      return output;
    },
    // 取出編輯用的顏色選項
    customer_device_colors: {
      set() {},
      get() {
        let vue = this,
          output = {};
        vue.get_editcustomer_deviceList.forEach((item) => {
          output[item.id] = [];
          vue.repair_targetKeyList[item.id_repair_target].colors.forEach(
            (c) => {
              // console.log("c", c);
              output[item.id].push({
                value: c.value,
                text: c.value,
                selected: c.value == item.color,
              });
            }
          );
        });
        return output;
      },
    },
    // 計算總價
    get_total_price() {
      let output = 0;
      this.orders_itemList.forEach((item) => {
        if (item.remove == "0") {
          output += this.$math.multiply(item.price, item.amount);
        }
      });
      return output;
    },
  },
  methods: {
    /**
     * 取出基本資料
     */
    get_data() {
      let vue = this,
        query = [
          {
            name: "opt_payment",
            data: {},
            sort: {
              name: 1,
            },
          },
          {
            name: "orders_item",
          },
          {
            name: "store",
            data: {},
            sort: {
              name: 0,
            },
          },
          {
            name: "opt_warranty",
            data: {},
            sort: {
              order_key: 0,
            },
          },
          { name: "customer" },
          { name: "customer_device" },
          {
            name: "repair_target",
            data: {
              active: {
                type: 0,
                value: 1,
              },
            },
            sort: {
              name: 1,
            },
          },
          {
            name: "product_class",
            data: {},
            sort: {
              name: 0,
            },
          },
        ];
      if (this.type == "add") {
        query.push({
          name: "orders",
        });
      } else {
        query.push(
          {
            name: "orders",
            data: {
              id: {
                type: 0,
                value: this.$route.params.id,
              },
            },
          },
          {
            name: "orders_item",
            data: {
              id_orders: {
                type: 0,
                value: vue.$route.params.id,
              },
            },
          }
        );
      }

      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          if (res.data.status == "ok") {
            if (vue.type == "add") {
              vue.orders = res.data.ordersEmpty;
              for (let k in vue.orders) {
                vue.orders[k] = "";
              }
              vue.orders.repair_target = [];
              vue.orders.customer_device = [];
              vue.orders.id_admin = vue.$store.state.user.id;
              vue.orders.id_store = vue.$store.state.id_store;
              vue.orders.day = vue.$moment().format("YYYY-MM-DD");
            } else {
              res.data.ordersList[0].customer_device = JSON.parse(
                res.data.ordersList[0].customer_device
              );
              vue.orders = res.data.ordersList[0];
              res.data.orders_itemList.map((item) => {
                // 如果是無客戶資料的項目就要把id恢復成0
                item.id_customer_device =
                  item.id_customer_device == "0" ? "" : item.id_customer_device;
                return item;
              });
              vue.orders_itemList = res.data.orders_itemList;
              vue.select_customer = true;
              vue.get_store_stock();
            }
            vue.storeList = res.data.storeList.map((item) => {
              return {
                id: item.id,
                text: item.name,
                value: item.id,
                selected: item.id == vue.$store.state.id_store,
              };
            });
            vue.opt_paymentList = res.data.opt_paymentList.map((item) => {
              return {
                id: item.id,
                text: item.name,
                value: item.id,
                selected: item.id == vue.orders.id_opt_payment,
              };
            });
            vue.product_classList = res.data.product_classList;

            vue.orders_itemEmpty = res.data.orders_itemEmpty;
            vue.orders_itemEmpty.id_opt_warranty = "";
            vue.orders_itemEmpty.amount = 1;
            vue.orders_itemEmpty.stock = 0;
            vue.orders_itemEmpty.remove = 0;
            vue.orders_itemEmpty.del_return = 0;
            vue.customerEmpty = res.data.customerEmpty;
            vue.customerEmpty.edit = true;
            vue.customer_deviceEmpty = res.data.customer_deviceEmpty;
            for (let i in vue.customer_deviceEmpty) {
              vue.customer_deviceEmpty[i] = "";
            }
            vue.opt_warrantyList = res.data.opt_warrantyList.map((item) => {
              return {
                id: item.id,
                text: item.name,
                value: item.id,
              };
            });
            vue.repair_targetList = res.data.repair_targetList.map((item) => {
              item.text = item.name;
              item.value = item.id;
              item.colors = JSON.parse(item.colors).map((itemc) => {
                return { text: itemc, value: itemc };
              });
              return item;
            });
          }
        });
    },
    /**
     * 取出客戶資料
     */
    get_customerList() {
      let vue = this,
        search = false;
      vue.search_customer.searching = true;
      vue.search_customer.customerList = [];
      let query = [
        {
          name: "customer",
          data: {},
        },
      ];
      if (vue.search_customer.name != "") {
        query[0].data.name = {
          type: 6,
          value: vue.search_customer.name,
        };
        search = true;
      }
      if (vue.search_customer.cel != "") {
        query[0].data.cel = {
          type: 6,
          value: vue.search_customer.cel,
        };
        search = true;
      }
      if (vue.search_customer.email != "") {
        query[0].data.email = {
          type: 6,
          value: vue.search_customer.email,
        };
        search = true;
      }
      if (search) {
        vue.$store
          .dispatch("get_form", {
            payload: {
              url: `data/get_data/?query=${JSON.stringify(query)}`,
            },
          })
          .then((res) => {
            if (res.data.status == "ok") {
              vue.search_customer.searching = false;
              vue.search_customer.customerList = res.data.customerList.map(
                (item) => {
                  item.edit = false;
                  return item;
                }
              );
              console.info("get customer data");
            }
          });
      } else {
        vue.search_customer.searching = false;
      }
    },
    /**
     * 儲存客戶資料 新增客戶資料用的
     * @param Object c 客戶資料
     */
    save_customer() {
      let vue = this;
      this.$store
        .dispatch("post_form", {
          payload: {
            url: "orders/add_customer",
            data: {
              data: JSON.stringify({
                name: vue.search_customer.name,
                cel: vue.search_customer.cel,
                email: vue.search_customer.email,
              }),
            },
          },
        })
        .then((res) => {
          if (res.data.status == "ok") {
            vue.search_customer.edit = false;
            vue.get_customerList();
          }
        });
    },
    /**
     * 選取客戶資料
     * @param Object c 客戶資料
     */
    get_customer_data(c) {
      let vue = this;
      vue.orders.name = c.name;
      vue.orders.cel = c.cel;
      vue.orders.email = c.email;
      vue.orders.id_customer = c.id;
      vue.select_customer = true;
      vue.search_customer.show = false;
      // 取出客戶裝置資料
      vue.customer_deviceList = [];
      let query = [
        {
          name: "customer_device",
          data: { id_customer: { type: 0, value: c.id } },
        },
      ];
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          if (res.data.status == "ok") {
            let T = true,
              cd_ids = [],
              cd_repair_ids = [];
            // 取出客戶裝置有維修紀錄的
            if (T) {
              res.data.customer_deviceList.forEach((item) => {
                cd_ids.push(item.id);
              });
              let query = [
                {
                  name: "orders_item",
                  data: { id_customer_device: { type: "8", value: cd_ids } },
                  group: ["id_customer_device"],
                },
              ];
              vue.$store
                .dispatch("get_form", {
                  payload: {
                    url: `data/get_data/?query=${JSON.stringify(query)}`,
                  },
                })
                .then((res) => {
                  res.data.orders_itemList.forEach((o) => {
                    cd_repair_ids.push(o.id_customer_device);
                  });
                  vue.has_order_cp = cd_repair_ids;
                });
            }
            vue.customer_deviceList = res.data.customer_deviceList.map(
              (item) => {
                item.text =
                  vue.repair_targetKeyList[item.id_repair_target].name +
                  "  【" +
                  item.color +
                  "】";
                item.value = item.id;
                item.edit = false;
                return item;
              }
            );
            vue.customer_deviceList.push({ text: "+ 新增裝置", value: "add" });
          }
        });
    },
    /**
     * 顯示新增客戶裝置表單
     */
    show_add_customer_device_form() {
      this.add_customer_device.show = true;
      this.add_customer_device.data = Object.assign(
        {},
        this.customer_deviceEmpty,
        { id_customer: this.orders.id_customer }
      );
    },
    // 儲存客戶裝置資料(新增)
    save_customer_device() {
      let vue = this;
      vue.add_customer_device.checkmsg = "";
      switch (true) {
        case vue.add_customer_device.data.id_repair_target == "":
          vue.add_customer_device.checkmsg = "請選擇 維修機種";
          break;
      }
      if (vue.add_customer_device.checkmsg == "") {
        vue.$store
          .dispatch("post_form", {
            payload: {
              url: "orders/add_customer_device",
              data: { data: JSON.stringify(vue.add_customer_device.data) },
            },
          })
          .then((res) => {
            if (res.data.status == "ok") {
              let cd = vue.add_customer_device.data;
              vue.customer_deviceList.unshift(
                Object.assign({}, cd, {
                  id: res.data.customer_device_id,
                  value: res.data.customer_device_id,
                  text:
                    vue.repair_targetKeyList[cd.id_repair_target].name +
                    "  【" +
                    cd.color +
                    "】",
                  selected: true,
                })
              );
              vue.close_add_customer_device_form();
            }
          });
      } else {
        vue.$swal.fire({
          icon: "error",
          title: vue.add_customer_device.checkmsg,
          timer: 1500,
          showConfirmButton: false,
          toast: true,
        });
      }
    },
    // 儲存客戶裝置資料(編輯)
    save_customer_device_edit(d) {
      let vue = this;
      vue.$swal.fire({
        title: "儲存中...",
        html: '<i class="fa fa-sync fa-spin fa-4x"></i>',
        showConfirmButton: false,
        didOpen() {
          vue.$store
            .dispatch("post_form", {
              payload: {
                url: "orders/upd_customer_device",
                data: { data: JSON.stringify(d) },
              },
            })
            .then((res) => {
              if (res.data.status == "ok") {
                d.text =
                  vue.repair_targetKeyList[d.id_repair_target].name +
                  "  【" +
                  d.color +
                  "】";
                d.edit = false;
                vue.$swal.fire({
                  title: "儲存完成",
                  icon: "success",
                  timer: 1500,
                });
              }
            });
        },
      });
    },
    // 刪除客戶裝置資料
    del_customer_device(d) {
      let vue = this;
      vue.$swal.fire({
        icon: "question",
        showLoaderOnConfirm: true,
        title: "確定要刪除嗎?",
        showCancelButton: true,
        preConfirm() {
          return vue.$store
            .dispatch("post_form", {
              payload: {
                url: "orders/del_customer_device",
                data: { id_customer_device: d.id },
              },
            })
            .then((res) => {
              if (res.data.status == "ok") {
                let index = vue.customer_deviceList.findIndex((item) => {
                  return item.id == res.data.id_customer_device;
                });
                vue.customer_deviceList.splice(index, 1);
              }
            });
        },
      });
    },
    // 關閉新增客戶裝置表單就把新增的選項取消勾選
    close_add_customer_device_form() {
      this.add_customer_device.show = false;
      this.customer_deviceList[
        this.customer_deviceList.length - 1
      ].selected = false;
    },
    // 顯示維修紀錄資料
    show_repairLog(customer_device) {
      let vue = this;
      vue.repairLog.show = true;
      vue.repairLog.get_data = true;
      vue.repairLog.customer_device = customer_device;
      let query = [
        {
          name: "orders_item",
          data: {
            id_customer_device: {
              type: 0,
              value: customer_device.id,
            },
          },
          sort: { ctime: 1 },
        },
      ];
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          if (res.data.status == "ok") {
            vue.repairLog.get_data = false;
            vue.repairLog.list = res.data.orders_itemList;
          }
        });
    },
    // 關閉維修紀錄資料
    close_repairLog_form() {
      this.repairLog.show = false;
      this.repairLog.list = "";
      this.repairLog.customer_device = {};
    },
    // 切換維修的項目
    switch_customer_device() {
      let vue = this;
      vue.orders.customer_device = [];
      vue.customer_deviceList.forEach((item) => {
        if (item.selected) {
          if (item.value != "add") {
            vue.orders.customer_device.push(item);
          } else {
            vue.show_add_customer_device_form();
          }
        }
      });
    },
    // 刪除銷售項目
    del_order_item(oi) {
      let vue = this;
      if (vue.type == "add") {
        vue.$set(oi, "del", true);
        vue.orders_itemList = vue.orders_itemList.filter((item) => {
          return !item.del;
        });
      } else if (vue.type == "edit") {
        // 如果訂單狀態是已售出就要額外詢問是不是要銷貨退回
        if (vue.orders.status == "0") {
          vue.$swal
            .fire({
              icon: "question",
              title: "銷貨退回",
              text: "已售出項目請確認商品狀況是否正常可重新入庫?",
              // showCancelButton: true,
              allowOutsideClick: false,
              confirmButtonText: "是(重新入庫)",
              denyButtonText: "否，報廢",
              showDenyButton: true,
            })
            .then((res) => {
              vue.$set(oi, "del_return", res.value ? 1 : 0);
            });
        }
        vue.$set(oi, "remove", 1);
      }
    },
    // 取出分店商品庫存
    get_store_stock() {
      let vue = this;
      let select_repair_target = [];
      vue.orders.customer_device.forEach((item) => {
        select_repair_target.push(item.id_repair_target);
      });
      vue.$swal.fire({
        allowOutsideClick: false,
        allowEscapeKey: false,
        title: "讀取商品資料中",
        html: '<i class="fa fa-sync fa-spin fa-2x"></i>',
        showConfirmButton: false,
      });
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `product/get_store_product/?id_store=${vue.orders.id_store}`,
          },
        })
        .then((res) => {
          if (res.data.status == "ok") {
            // 只有新增的時候才可以清空資料
            if (vue.type == "add") {
              vue.orders_itemList = [];
            }
            vue.productList = res.data.productList.map((item) => {
              item.repair_target = JSON.parse(item.repair_target);
              return item;
            });
            vue.$swal.close();
          }
        });
    },
    add_orders_item(order_item) {
      let vue = this;
      let id_product = vue.new_product[order_item.customer_device.id];
      console.info("新增銷售品項:", id_product);
      if (id_product && id_product != "") {
        let index =
          vue.orders_itemList.findIndex((item) => {
            return (
              item.id_product == id_product &&
              item.id_customer_device == order_item.customer_device.id
            );
          }) == -1;
        if (index) {
          vue.orders_itemList.push(
            Object.assign({}, vue.orders_itemEmpty, {
              id: `add_${vue.orders_item_count++}`,
              id_customer_device: order_item.customer_device.id,
              id_product: id_product,
              product_name: vue.productKeyList[id_product].name,
              product_code: vue.productKeyList[id_product].code,
              price: vue.productKeyList[id_product].price,
              cost: vue.productKeyList[id_product].cost,
              stock: parseInt(vue.productKeyList[id_product].stock),
            })
          );
        }
      }
      vue.new_product[order_item.customer_device.id] = "";
    },
    /**
     * 儲存訂單
     * @param String type 儲存類型
     */
    save_orders(type) {
      let vue = this,
        msg = "";
      if (vue.orders_itemList.length == 0) {
        msg = "請至少新增一個品項";
      }
      if (type == "send") {
        switch (true) {
          case vue.orders.id_store == null || vue.orders.id_store == "":
            msg = "請選取目前分店";
            break;
          case !vue.select_customer && vue.orders_itemList.length == 0:
            msg = "請填寫客戶資料";
            break;
          case vue.orders.id_opt_payment == null ||
            vue.orders.id_opt_payment == "":
            msg = "請填寫付款方式";
            break;
        }
        // 判斷每個品項裡面的欄位
        if (msg == "") {
          vue.orders_itemList.findIndex((item) => {
            switch (true) {
              case item.id_opt_warranty == null || item.id_opt_warranty == "":
                msg = "請填寫保固方式";
                return true;
              default:
                return false;
            }
          });
        }
        if (msg == "") {
          vue.$swal.fire({
            title: "確定要儲存嗎?",
            showCancelButton: true,
            icon: "info",
            showLoaderOnConfirm: true,
            allowOutsideClick: false,
            preConfirm() {
              vue.orders.status = "0";
              return vue.$store
                .dispatch("post_form", {
                  payload: {
                    url: "orders/save_orders",
                    data: {
                      data: JSON.stringify({
                        orders: vue.orders,
                        orders_item: vue.orders_itemList,
                      }),
                      save_type: type,
                    },
                  },
                })
                .then((res) => {
                  if (res.data.status == "ok") {
                    vue.$swal
                      .fire({
                        icon: "success",
                        title: "儲存完成!",
                        timer: 1500,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                      })
                      .then(() => {
                        location.reload();
                      });
                  }
                });
            },
          });
        } else {
          vue.$swal.fire({
            icon: "error",
            title: msg,
            timer: 1500,
            showConfirmButton: false,
            toast: true,
            // position: "bottom-right",
          });
        }
      } else {
        if (msg == "") {
          vue.orders.status = "1";
          vue.$swal.fire({
            title: "儲存中",
            html: '<i class="fa fa-sync fa-spin fa-4x"></i>',
            showConfirmButton: false,
            didOpen() {
              vue.$store
                .dispatch("post_form", {
                  payload: {
                    url: "orders/save_orders",
                    data: {
                      data: JSON.stringify({
                        orders: vue.orders,
                        orders_item: vue.orders_itemList,
                      }),
                      save_type: type,
                    },
                  },
                })
                .then((res) => {
                  if (res.data.status == "ok") {
                    vue.$swal
                      .fire({
                        icon: "success",
                        title: "儲存完成!",
                        timer: 1500,
                        showConfirmButton: false,
                      })
                      .then(() => {
                        // console.log(res.data.orders_item_ids);
                        vue.orders.id = res.data.orders_id;
                        vue.$router.push(
                          `/orders/edit/${res.data.orders_id}/Y`
                        );
                        vue.orders_itemList.map((oi) => {
                          oi.id = res.data.orders_item_ids[oi.id]
                            ? res.data.orders_item_ids[oi.id]
                            : oi.id;
                          return oi;
                        });
                      });
                  }
                });
            },
          });
        } else {
          vue.$swal.fire({
            icon: "error",
            title: msg,
            timer: 1500,
            showConfirmButton: false,
            toast: true,
            // position: "bottom-right",
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" slot-scope>
.remark_text.md-form textarea.md-textarea {
  padding: 0;
}
</style>